<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Type' : 'Create Type'" />
        <div>
          <md-button :to="{ name: 'Estate Types' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateType">
          <md-field :class="getValidationClass('first_name')">
            <label>Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
          </md-field>
          <mdc-button :loading="loading">{{
            id ? "Update Type" : "Create Type"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("realtor");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {},
    loading: false,
  }),
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["createType", "updateType", "getType"]),
    async validateType() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        try {
          this.loading = true;
          let call = this.id ? this.updateType(data) : this.createType(data);
          let msg = this.id ? "Type Updated" : "Type Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Estate Types" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getType(this.id).then((type) => {
        this.form = {
          title: type.title,
          id: type.id,
        };
      });
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-Types {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
